import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';

const CustomPrintModal3 = ({ show, handleClose, title1, title2, title3,title4,title5, total1, total2, content, copia }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { nombre, usuario,rolActivo, roles,id } = useSelector(state => state.sesion);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        const doc = new jsPDF('p', 'pt', 'letter'); // Establecer tamaño de hoja a carta

        const marginLeftRight = 55; // Márgenes izquierdo y derecho
        const marginTop = 35; // Margen superior
        const marginBottom = 75; // Ajustar el margen inferior para bajar el pie de página
        let headerHeight = 60; // Altura de la cabecera

        const addWatermark = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setFontSize(72);
            doc.setTextColor(240, 240, 240); // marca de agua
            doc.setFont("helvetica");
            doc.text("COPIA", pageWidth / 2, pageHeight / 2, {
                angle: 45,
                align: 'center'
            });
        };
        const addHeader = (doc, pageNumber) => {
            const pageWidth = doc.internal.pageSize.getWidth();
        
            // Obtener las propiedades de la imagen
            const imgProps = doc.getImageProperties(cabeceraCud);
            const imgWidth = imgProps.width;
            const imgHeight = imgProps.height;
        
            // Calcular el tamaño de la imagen para que sea un cuadrado perfecto
            const maxDimension = Math.min(headerHeight, headerHeight); // Mantener la imagen como un cuadrado
            const scale = maxDimension / Math.max(imgWidth, imgHeight); // Escalar proporcionalmente
            const scaledWidth = imgWidth * scale;
            const scaledHeight = imgHeight * scale;
        
            // Dibujar la imagen alineada a la izquierda
            doc.addImage(
                cabeceraCud,
                'PNG',
                marginLeftRight, // Posición horizontal (alineada a la izquierda)
                marginTop, // Posición vertical
                scaledWidth,
                scaledHeight
            );
        
            // Agregar el texto "Colegio de Arquitectos de Tarija" a la derecha
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.setTextColor(0, 0, 0); // Texto negro
            doc.text(
                "COLEGIO DE ARQUITECTOS TARIJA", 
                marginLeftRight + scaledWidth+ 15, // Posición horizontal (alineada a la derecha)
                marginTop + scaledHeight / 2, // Centrar verticalmente con respecto a la imagen
                { align: 'left' }
            );
            doc.setFont("times", "normal");
            // Agregar el número de página si es mayor a 0
            doc.setFontSize(12);
            if (pageNumber > 0) {
                doc.text(
                    `Página ${pageNumber}`, 
                    pageWidth - marginLeftRight, 
                    marginTop + headerHeight + 20, 
                    { align: 'right' }
                );
            }
        };

        const addFooter = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setDrawColor(0, 50, 0); // Verde más oscuro
            doc.setLineWidth(1);
            doc.line(marginLeftRight, pageHeight - marginBottom - 10, pageWidth - marginLeftRight, pageHeight - marginBottom - 10);

            doc.setFontSize(12);
            doc.setTextColor(0, 50, 0); // Verde más oscuro
            doc.setFont("times", "normal");
            doc.text('Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(10);
            doc.text(
                `Impreso el: ${new Date().toLocaleString()} | Usuario: ${nombre}`, 
                pageWidth - marginLeftRight, 
                pageHeight - marginBottom + 40, 
                { align: 'right' }
            );
        };

        // Validaciones: Asegurar que los valores no sean null o undefined
        const validTitle1 = title1 || '';
        const validTitle2 = title2 || '';
        const validTitle3 = title3 || '';
        const validTitle4 = title4 || '';
        const validTitle5 = title5 || '';
        const validTotal1 = total1 || '';
        const validTotal2 = total2 || '';
        

        // Inicializa el número de página
        let pageNumber = 0;

        const addTitles = (doc) => {
            doc.setFontSize(16);
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0); // Asegurar que los títulos estén en negro
        
            let currentY = marginTop + headerHeight + 40; // Posición inicial vertical
        
            // Validar que los títulos no sean nulos o vacíos
            if (validTitle1) {
                const title1Lines = doc.splitTextToSize(validTitle1, doc.internal.pageSize.getWidth() - 2 * marginLeftRight);
                doc.text(title1Lines, doc.internal.pageSize.getWidth() / 2, currentY, { align: 'center' });
                currentY += title1Lines.length * 16; // Ajustar la posición vertical según el número de líneas
            }
        
            doc.setFontSize(12);
            doc.setFont("times", "normal");
        
            const addTitleLine = (title, yOffset) => {
                if (title) {
                    const titleLines = doc.splitTextToSize(title, doc.internal.pageSize.getWidth() - 2 * marginLeftRight);
                    doc.text(titleLines, marginLeftRight, currentY, { align: 'left' });
                    currentY += titleLines.length * yOffset; // Ajustar la posición vertical según el número de líneas
                }
            };
        
            addTitleLine(validTitle2, 14); // Altura de línea para títulos normales
            addTitleLine(validTitle3, 14);
            addTitleLine(validTitle4, 14);
            addTitleLine(validTitle5, 14);
        
            return currentY; // Retornar la posición final de los títulos
        };
      
        const addTotals = (doc, secondLastX, lastX) => {
            if (!secondLastX || !lastX) return;
        
            console.log("print autoTable", doc.autoTable.previous)
           
            // Calcular la posición vertical para los totales
            const startY = doc.autoTable.previous.finalY + 25;
        
            // Establecer estilo para los totales
            doc.setFontSize(11);
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0);
            doc.text("TOTALES:", marginLeftRight, startY);
            // Dibujar los totales debajo de las columnas correspondientes
            if (validTotal1) {
                doc.text(validTotal1.toString(), secondLastX, startY, { align: "right" });
            }
            if (validTotal2) {
                doc.text(validTotal2.toString(), lastX, startY, { align: "right" });
            }
         
    // Añadir espacio después de los totales
    const spaceAfterTotals = startY + 80; // Ajusta este valor para crear más espacio (más de una fila)

    // Agregar líneas para firmas
    doc.setFontSize(10); // Tamaño de fuente para las firmas
    doc.setFont("times", "normal"); // Fuente normal para las firmas
    doc.setTextColor(0, 0, 0); // Texto en negro

    // Línea para la primera firma (izquierda)
    const firma1X = marginLeftRight + 60; // Posición X de la primera línea
    doc.text("__________________________", firma1X, spaceAfterTotals);// Etiqueta para la primera firma

    // Línea para la segunda firma (derecha)
    const firma2X = doc.internal.pageSize.getWidth() - marginLeftRight - 200; // Posición X de la segunda línea
    const firma2Y = spaceAfterTotals; // Misma posición Y que la primera línea
    doc.text("__________________________", firma2X, firma2Y);
 // Etiqueta para la segunda firma
             
        }
        // Draw watermark on the first page before any content if copia is true
        if (copia) {
            addWatermark(doc);
        }

        if (typeof content === 'string') {
            addHeader(doc, pageNumber);
            addTitles(doc);
            doc.text(content, marginLeftRight, marginTop + headerHeight + 125);
            addTotals(doc);
            addFooter(doc);
        } else {
            let lastX;
            let secondLastX ;
            const titlesEndY = addTitles(doc); // Obtener la posición final de los títulos

            // Calcular el ancho de las dos últimas columnas basado en el texto
            const secondLastColumnWidth = doc.getTextWidth(validTotal1); // Ancho del texto de validTitle4 con un margen adicional
            const lastColumnWidth = doc.getTextWidth(validTotal2); // Ancho del texto de validTitle5 con un margen adicional

            autoTable(doc, {
                head: content.head,
                body: content.body,
                startY: titlesEndY,
                margin: { top: marginTop + headerHeight + 120, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom + 10 },
                didDrawPage: function (data) {
                    pageNumber++;
                    addHeader(doc, pageNumber);
                    if(pageNumber>1){const newTitlesEndY = addTitles(doc); // Recalcular la posición de los títulos en cada página
                    data.settings.startY = newTitlesEndY;}
                    if (copia) addWatermark(doc);
                    addFooter(doc);
                },
                willDrawCell: function (data) {
                    // Verificar si es la última fila de la tabla
                    if (data.row.index === content.body.length - 1) {
                        const pageHeight = doc.internal.pageSize.getHeight();
                        const remainingSpace = pageHeight - data.cursor.y - marginBottom;
        
                        // Calcular el espacio necesario para los totales y las firmas
                        const requiredSpace = 100; // Espacio estimado para totales y firmas
        
                        if (remainingSpace < requiredSpace) {
                            // Forzar un salto de página si no hay suficiente espacio
                            doc.addPage();
                            data.cursor.y = titlesEndY; // Reiniciar la posición vertical
                        }
                    }
                },
                styles: { 
                    fontSize: 9,
                    lineColor: [0, 0, 0],
                    lineWidth: 0.5,
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                },
                bodyStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                },
                columnStyles: {
                    // Alinear las dos últimas columnas a la derecha
                    [content.head[0].length - 2]: { halign: 'right', cellWidth: secondLastColumnWidth }, // Penúltima columna
                    [content.head[0].length - 1]: { halign: 'right', cellWidth: lastColumnWidth }, // Última columna
                },
                pageBreak: 'auto',
                rowPageBreak:'avoid'
            });
            if (doc.autoTable.previous) {
                const pageWidth = doc.internal.pageSize.getWidth(); // Ancho total de la página
                // Calcular las posiciones absolutas de las dos últimas columnas
                lastX = pageWidth- secondLastColumnWidth; // Última columna desde el borde derecho
                secondLastX = lastX - lastColumnWidth; 
                console.log("cols secondLastX",secondLastX,"lastX",lastX)
            }
            addTotals(doc, secondLastX, lastX);
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista previa del Recibo"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModal3;
