import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAfiliado, modifyAfiliado, fetchAfiliados, modifySituacionAfiliado, fetchInscripcionesAfiliados } from '../../reducers/afiliado_slice';
import { fetchPersonas, fetchPersonasNoAfiliadas } from '../../reducers/persona_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import PersonaForm from '../persona/PersonaForm';
import { apellidosNombreFullPersona, formatearFechaSimple } from '../../util/customFormatter';
import SelectPersona from '../persona/SelectPersona';
import { fetchLugares } from '../../reducers/lugar_slice';
import { fetchRegionesPorPais } from '../../reducers/region_slice';

const initialState = {
    id_persona: "",
    persona:null,
    aliasSituacion:"",
    observacion: "",
}

const initialFormDetalles = {title:"Nueva Inscripción",
    headerColor:"#254159", 
    buttonText:"Crear" }

const AfiliadoForm = ({ currentAfiliado, showModalForm, handleCloseForm }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
    const situaciones = useSelector(state => state.situacion.situaciones)

    const dispatch = useDispatch();
    const personas = useSelector(state => state.persona.personas);
    const persona = useSelector(state => state.persona.current);
    const afiliados = useSelector(state => state.afiliado.afiliados);
    const requestStatus = useSelector(state => state.afiliado.statusSend);
    const errorInRequest = useSelector(state => state.afiliado.error);
    const [currentPersona, setCurrentPersona] = useState(null);
    const [showModalPersona, setShowModalPersona] = useState(false);
    const lugares = useSelector(state => state.lugar.lugares) || [];
    const regiones = useSelector(state => state.region.regiones) || [];

      useEffect(()=>{
        dispatch(fetchLugares());
        dispatch(fetchRegionesPorPais("BOLIVIA"));
      },[dispatch])


    useEffect(() => {
        if(showModalForm)
        {
        dispatch(fetchPersonasNoAfiliadas());

        if (currentAfiliado) {
            dispatch(fetchAfiliados());
            setFormDetalles({title:"Cambio de situación afiliado",
                headerColor:"#255957", 
                buttonText:"Actualizar" })
            setDatos({
                observacion:"",
                id_lugar_residencia:currentAfiliado.id_lugar_residencia,
                id_region_filtro:currentAfiliado.lugar_residencia?.id_region,
                aliasSituacion: currentAfiliado.situacion.alias,
            });
            setIsModified(false);
        } else {
            dispatch(fetchInscripcionesAfiliados());
            resetForm();
        }}
    }, [currentAfiliado,showModalForm, dispatch]);

    useEffect(() => {
           if(persona){ setDatos({
                id_persona: persona.id,
                aliasSituacion:"preinscrito",
                observacion: "",
            });}
    }, [persona]);

    const cambiarSituacionAfiliado = (afiliado) => {
        const {id, ...afiliadoData} = afiliado
        dispatch(modifySituacionAfiliado({id, afiliado:afiliadoData}))
        .then(data => dispatch(fetchAfiliados()))
        handleCloseForm();
    };

    const handleSubmit = e => {
        e.preventDefault();

       if((!currentAfiliado && datos.persona && datos.aliasSituacion) || currentAfiliado){ 
        if (isModified) {
            setError('');

            if (currentAfiliado && (datos.aliasSituacion !== "preinscrito" && datos.aliasSituacion !== "preinscrito2")) 
            {

                if (datos.aliasSituacion === "activo2") {
                    // Verificar antigüedad de 25 años
                    const fechaRegistro = new Date(currentAfiliado.fecha_reg_cab); // Convertir fecha_reg_cab a objeto Date
                    const fechaActual = new Date();
                    let antiguedad = fechaActual.getFullYear() - fechaRegistro.getFullYear();
                    // Ajustar si el mes/día actual es menor al mes/día de registro
                    if (
                        fechaActual.getMonth() < fechaRegistro.getMonth() ||
                        (fechaActual.getMonth() === fechaRegistro.getMonth() && fechaActual.getDate() < fechaRegistro.getDate())
                    ) {
                        antiguedad--;
                    }
                    console.log("Activos2",fechaActual.getMonth(), fechaRegistro.getMonth())
                    if (antiguedad < 25) {
                        Swal.fire({
                            icon: 'error',
                            title: `Cambio a ACTIVO 25+ no permitido`,
                            text: 'El afiliado no cumple con los 25 años de antigüedad requeridos.',
                        });
                        return; // Detener el proceso si no cumple con la antigüedad
                    }
                }

                // let datosToAdd = datos.aliasSituacion==="trasladoloc" || datos.aliasSituacion==="trasladodep"? {
                //     id_lugar_residencia:datos.id_lugar_residencia} :{}
                cambiarSituacionAfiliado({
                    id: currentAfiliado.id,
                    situacion: datos.aliasSituacion,
                    comentario: "Cambio de situación",
                    observacion: datos.observacion,
                    id_lugar_residencia:datos.id_lugar_residencia
                });
            } else {
                dispatch(addAfiliado(datos)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el afiliado.',
                        });
                    } else {
                        handleCloseForm();
                        console.log("Va a cambiar lista")
                        dispatch(fetchInscripcionesAfiliados());
                    }
                });
            }
        } else {
            handleCloseForm();
        }}else{setError("Debe seleccionar una persona y una situación para proceder con la inscripción")}
    };

    const resetForm = () => {
        setDatos(initialState);
        setFormDetalles(initialFormDetalles);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }

    const handleSelect = (name, object) => {
        const objetoId=object? object.id : ""
        setIsModified(true);
        setDatos((prevDatos) => ({ ...prevDatos, [`id_${name}`]: objetoId, [name]:object }));
    };

    const personasAfiliadas = personas.filter(persona => afiliados.some(afiliado => afiliado.id_persona === persona.id));
    const filteredSituaciones= situaciones.filter(situ=>
        situ.alias !== "preinscrito" && situ.alias !== "externo" && situ.alias !== "preinscrito2" && situ.alias !== "preinscrito3"
    )

    const handleCreatePersona = () => {
        setCurrentPersona(null);
        setShowModalPersona(true);
      };
      
  const handleCloseCreatePersona = () => {
    setShowModalPersona(false);
    setCurrentPersona(null);
  };
const filteredLugares = datos.id_region_filtro? [...lugares].filter(lugar=> `${lugar.id_region}` === `${datos.id_region_filtro}`) : lugares

    return (
        <>
        <Modal show={showModalForm} onHide={handleCloseForm} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit} className='normal-form'>
                
                    <Row xs={1} md={2}>
                       {currentAfiliado &&  <>
                         <div className='col'>
                                <p>N° de registro: <strong>{currentAfiliado?.num_reg}</strong></p>
                                <p>Fecha de registro: <strong>{formatearFechaSimple(currentAfiliado?.fecha_reg_cab)}</strong></p>
                                <p>Afiliado:<strong>{currentAfiliado?.persona ? apellidosNombreFullPersona(currentAfiliado?.persona):""}</strong> </p>
                                <p>C.I.: <strong>{currentAfiliado?.persona?.ci }</strong></p>
                                <p>Situación actual:  <strong>{currentAfiliado?.situacion.nombre}</strong></p>
                                <p>Residencia actual:  <strong>{currentAfiliado?.lugar_residencia?.nombre}</strong></p>
                          
                            </div></>}
                        
                      {currentAfiliado &&
                       <>
                       <Col className='col-form mt-2'>
                           <Form.Label>Situación</Form.Label>
                            <Form.Control
                                as="select"
                                className="form-control"
                                size="sm"
                                value={datos.aliasSituacion}
                                name='aliasSituacion'
                                onChange={handleInputChange}
                                disabled={!currentAfiliado}
                                >
                                <option value="">- Sin seleccionar -</option>
                                {filteredSituaciones.map(situ =>
                                    <option key={situ.id} value={situ.alias}>{situ.nombre}</option>
                                )}

                                </Form.Control>
                     <Form.Label className='mt-3'>Observación</Form.Label>
                            <Form.Control
                                className='mb-4'
                                as="textarea"
                                name="observacion"
                                onChange={handleInputChange}
                                value={datos.observacion}
                            />
                    </Col>
                    </> }
                       
                    </Row>
                  {currentAfiliado && (datos.aliasSituacion==="trasladoloc" || datos.aliasSituacion==="trasladodep") && <Row className='mt-4'>
                        <Col>
                                   
                        <Form.Label>Región (Filtro opcional)</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_region_filtro"
                                value={datos.id_region_filtro}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">- Sin seleccionar -</option>
                                {regiones.map(lugar =>
                                 (<option key={lugar.id} value={lugar.id}>{`${lugar.nombre}`}</option>
                                ))}
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Label>Lugar de residencia</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_lugar_residencia"
                                value={datos.id_lugar_residencia}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">- Sin seleccionar -</option>
                                {filteredLugares.map(lugar =>
                                (<option key={lugar.id} value={lugar.id}>{`${lugar.nombre}`}</option>
                                ))}
                            </Form.Control>
                            
                        </Col>
                    </Row>}
                {!currentAfiliado &&<>
                    <SelectPersona labelText="Persona"  
                                    personaSelected={datos.persona} 
                                    onSelect={handleSelect} 
                                    excludeList={personasAfiliadas}
                                    actualizarListaFn={fetchPersonasNoAfiliadas}/>
                        <div className='col-12 mt-3'>
                        <Form.Label>Tipo de inscripción</Form.Label>
                        <div>
                            <Form.Check
                            type="radio"
                            label="ACTIVO"
                            name="aliasSituacion"
                            value="preinscrito"
                            checked={datos.aliasSituacion === "preinscrito"}
                            onChange={handleInputChange}
                            />
                            <Form.Check
                            type="radio"
                            label="EXTERNO"
                            name="aliasSituacion"
                            value="preinscrito3"
                            checked={datos.aliasSituacion === "preinscrito3"}
                            onChange={handleInputChange}
                            />
                        </div>
                        </div>          
                        </>  
                    }
                
                    <div className="d-flex justify-content-between mt-5">
                    <button className="button-mine btn-cancel-outline" onClick={handleCloseForm} type='button'>
                            Cancelar
                        </button>
                        <button className="button-mine btn-success-outline" type="submit" disabled={!isModified}>
                        <i className="bi bi-check-circle"></i> {formDetalles.buttonText}
                        </button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default AfiliadoForm;
